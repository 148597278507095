<template>
    <vs-popup title="Take GoCardless Payment" :active.sync="show">
        <div class="flex-auto mr-6 w-full">
            <label for="" class="vs-input--label">Payment Date</label>

            <datepicker
                placeholder="Payment Date"
                v-model="formData.date"
            ></datepicker>
        </div>

        <vs-input
            type="number"
            v-model="formData.amount"
            label="Amount"
            class="mb-3 w-full"
        />
        <vs-textarea v-model="formData.description" label="Description" />

        <div>
            <div v-if="goCardLess.customer" class="mb-3">
                <h5>Customer Details</h5>
                <p>Customer : {{ goCardLess.customer.company_name }}</p>
                <p>GoCardless customer id: {{ goCardLess.customer.id }}</p>
                <p>Email: {{ goCardLess.customer.email }}</p>
            </div>
            <p v-else>Unable to fetch go cardless customer</p>

            <div v-if="goCardLess.mandate">
                <h5>Mandate Details</h5>
                <p>GoCardless mandate id: {{ goCardLess.mandate.id }}</p>
                <p>
                    Next possible charge date:
                    {{ goCardLess.mandate.next_possible_charge_date }}
                </p>
                <p>Status: {{ goCardLess.mandate.status }}</p>
            </div>
            <p v-else>Unable to fetch go cardless mandate</p>
        </div>

        <vs-divider />

        <vs-button
            :disabled="!validateForm || loading"
            v-on:click="submitPayment"
            color="secondary"
            class="mr-3"
            >Confirm
        </vs-button>
        <vs-button v-on:click="$emit('close')" type="flat">Cancel </vs-button>
        <vs-progress v-show="loading" indeterminate color="primary"
            >primary</vs-progress
        >
    </vs-popup>
</template>

<script>
import { http } from '@/services'
import Datepicker from 'vuejs-datepicker'

export default {
    name: 'TakePaymentModal',
    mixins: [],
    props: ['showData', 'invoiceData'],
    watch: {
        invoiceData: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.formData.invoice_id = value.id
                    this.formData.amount = value.amount
                    this.formData.description = value.description
                    this.formData.date = new Date()
                    if (value.broker.go_card_less_mandate_id)
                        this.getGoCardLessMandateData()
                    if (value.broker.go_card_less_customer_id)
                        this.getGoCardLessCustomerData()
                } else {
                    this.clearFrom()
                }
            },
        },
    },
    components: {
        Datepicker,
    },
    computed: {
        show: {
            get() {
                return this.showData
            },
            set(value) {
                this.$emit('close')
            },
        },
        validateForm() {
            return !!(
                this.formData.amount &&
                this.formData.description &&
                this.formData.date &&
                this.formData.invoice_id
            )
        },
    },
    data() {
        return {
            formData: {
                amount: null,
                description: null,
                date: null,
                invoice_id: null,
            },
            goCardLess: {
                mandate: null,
                customer: null,
            },
            loading: false,
        }
    },
    methods: {
        submitPayment() {
            if (
                this.formData.amount &&
                this.formData.description &&
                this.formData.date &&
                this.formData.invoice_id
            ) {
                this.loading = true
                http.post('broker_take_payment', this.formData)
                    .then((response) => {
                        this.loading = false

                        this.clearForm()
                        this.$vs.notify({
                            text: 'Payment scheduled',
                            color: 'success',
                        })
                        this.$emit('submitted')
                        this.$emit('close')
                    })
                    .catch((error) => {
                        this.loading = false
                        this.$vs.notify({
                            text: error.response.data,
                            color: 'danger',
                        })
                    })
            }
        },
        clearForm() {
            this.formData.invoice_id = null
            this.formData.amount = null
            this.formData.description = null
            this.goCardLess.mandate = null
            this.goCardLess.customer = null
        },
        getGoCardLessMandateData() {
            http.get('gocardless_mandate_detail', {
                params: {
                    mandate_id: this.invoiceData.broker.go_card_less_mandate_id,
                },
            })
                .then((response) => {
                    this.goCardLess.mandate = response.data
                    if (response.data.next_possible_charge_date) {
                        this.formData.date = new Date(
                            response.data.next_possible_charge_date
                        )
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getGoCardLessCustomerData() {
            http.get('gocardless_customer_detail_2', {
                params: {
                    customer_id: this.invoiceData.broker
                        .go_card_less_customer_id,
                },
            })
                .then((response) => {
                    this.goCardLess.customer = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
