<template>
    <vx-card title="Wills Billed">
        <vs-table
            :data="data"
            stripe
            class="w-full"
            @selected="
                $router.push({ name: 'user', params: { id: $event.user.id } })
            "
        >
            <template slot="thead">
                <vs-th>User</vs-th>
                <vs-th>Coupon</vs-th>
                <vs-th>Paid</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        <span v-if="tr.user.details">{{
                            tr.user.details.full_name
                        }}</span>
                    </vs-td>

                    <vs-td>
                        <span v-if="tr.coupon">{{ tr.coupon.code }}</span>
                        <small v-if="tr.coupon.price_discount_percentage"
                            ><br />{{
                                tr.coupon.price_discount_percentage
                            }}%</small
                        >
                    </vs-td>
                    <vs-td
                        ><span v-if="tr.payment_details"
                            >£{{ tr.payment_details.will_amount / 100 }}</span
                        ></vs-td
                    >
                </vs-tr>
            </template>
        </vs-table>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'WillsInvoiceCard',
    mixins: [textFormat],
    props: ['data'],
}
</script>
