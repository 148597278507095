<template>
    <div>
        <label for="" class="vs-input--label" :class="{ 'text-white': white }"
            ><span v-if="selected">User</span>
        </label>

        <v-select
            v-model="selected"
            :options="users"
            @search="fetchUsers"
            placeholder="Search Users"
            :class="{ 'select-white': white }"
        ></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'UsersSelect',
    components: {
        vSelect,
    },
    props: ['user', 'initialUser', 'reset', 'white'],
    watch: {
        selected(value) {
            this.$emit('selectedUser', value)
        },
        user(value) {
            this.selected = value
        },
        reset(value) {
            if (value) this.selected = null
        },
        initialUser: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value) {
                    this.selected = value
                } else {
                    this.selected = null
                }
            },
        },
    },
    data() {
        return {
            users: [],
            selected: '',
        }
    },
    methods: {
        fetchUsers(search, loading) {
            if (search) {
                http.get('users_widget/' + search)
                    .then((response) => {
                        this.users = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                this.users = []
            }
        },
    },
}
</script>

<style>
.select-white .vs__search::placeholder,
.select-white .vs__dropdown-toggle,
.select-white .vs__dropdown-menu {
    background: #fff;
    border: none;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;
}

.select-white .vs__clear,
.select-white .vs__open-indicator {
    fill: var(--vs-primary);
}
</style>
