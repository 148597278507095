<template>
    <vx-card class="showOverflow">
        <div class="flex w-full mb-6 flex-wrap">
            <div class="flex-auto">
                <vs-button
                    :color="saveChanges ? 'secondary' : 'primary'"
                    v-on:click="$emit('saveBack')"
                    class="mr-3"
                    :type="saveChanges ? 'filled' : 'flat'"
                    >Save & Back
                </vs-button>
            </div>

            <div class="flex-auto">
                <vs-button
                    :color="saveChanges ? 'secondary' : 'primary'"
                    v-on:click="$emit('save')"
                    class="mr-3"
                    :type="saveChanges ? 'filled' : 'flat'"
                    >Save
                </vs-button>
            </div>

            <div class="flex-auto">
                <vs-button
                    v-on:click="isLocked = !isLocked"
                    radius
                    :color="isLocked ? 'secondary' : 'primary'"
                    type="flat"
                    icon-pack="feather"
                    :icon="isLocked ? 'icon-lock' : 'icon-unlock'"
                ></vs-button>
            </div>
            <div class="flex-auto">
                <vs-button
                    v-on:click="show.deleteModal = true"
                    radius
                    :disabled="isLocked"
                    color="danger"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-trash"
                ></vs-button>
            </div>
        </div>

        <div class="flex w-full mb-6">
            <div class="flex-auto mr-6">
                <label for="" class="vs-input--label">From</label>

                <datepicker
                    :disabled="isLocked"
                    placeholder="From Date"
                    v-model="fromDate"
                ></datepicker>
            </div>

            <div class="flex-auto">
                <label for="" class="vs-input--label">To</label>

                <datepicker
                    :disabled="isLocked"
                    placeholder="To Date"
                    v-model="toDate"
                ></datepicker>
            </div>
        </div>

        <div class="vx-col w-full">
            <div class="flex mb-3">
                <label for="" class="mr-auto">Approved</label>
                <vs-switch
                    :disabled="isLocked"
                    color="success"
                    v-model="approved"
                    vs-icon="done"
                />
            </div>
            <div class="flex mb-3">
                <label for="" class="mr-auto">Sent</label>
                <vs-switch
                    :disabled="isLocked"
                    color="success"
                    v-model="sent"
                    vs-icon="done"
                />
            </div>
            <div class="flex">
                <label for="" class="mr-auto">Paid</label>
                <vs-switch
                    :disabled="isLocked"
                    color="success"
                    v-model="paid"
                    vs-icon="done"
                />
            </div>

            <div class="flex mt-4">
                <div class="mr-4">
                    <vs-button
                        v-on:click="show.takePaymentApproveModal = true"
                        :disabled="disablePayment"
                        color="success"
                        type="border"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        >Payment & Approve
                    </vs-button>
                </div>

                <div>
                    <vs-button
                        v-on:click="show.takePaymentModal = true"
                        :disabled="disablePayment"
                        color="success"
                        type="border"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        >Payment Custom
                    </vs-button>
                </div>
            </div>
            <p v-if="invoice.invoicepayment" class="text-success mt-2 text-sm">
                Payment status - <span>{{ paymentStatus }}</span>
            </p>
            <p v-if="disablePayment" class="text-secondary mt-2 text-sm">
                Broker GoCardless not setup
            </p>
        </div>

        <!-- Delete Will Modal -->
        <vs-popup title="Delete Invoice?" :active.sync="show.deleteModal">
            <vs-button v-on:click="deleteInvoice" color="secondary" class="mr-3"
                >Confirm
            </vs-button>
            <vs-button v-on:click="show.deleteModal = false" type="flat"
                >Cancel
            </vs-button>
        </vs-popup>
        <TakePayment
            :showData="show.takePaymentModal"
            @close="show.takePaymentModal = false"
            :invoiceData="data"
            class="showOverflow"
            @submitted="$emit('refreshPayment')"
        />
        <TakePaymentApproveModal
            :showData="show.takePaymentApproveModal"
            @close="show.takePaymentApproveModal = false"
            :invoiceData="data"
            class="showOverflow"
            @submitted="$emit('back')"
        />
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import Datepicker from 'vuejs-datepicker'

import TakePayment from '../Modals/TakePayment'
import TakePaymentApproveModal from '../Modals/TakePaymentApprove'

export default {
    name: 'ActionCard',
    mixins: [textFormat],
    props: [
        'data',
        'inSent',
        'inPaid',
        'inApproved',
        'inToDate',
        'inFromDate',
        'inLocked',
        'saveChanges',
        'renderChanges',
    ],
    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value) {
                    this.invoice = value
                }
            },
        },
    },
    components: {
        TakePaymentApproveModal,
        Datepicker,
        TakePayment,
    },
    computed: {
        paymentStatus() {
            if (this.invoice && this.invoice.invoicepayment) {
                if (this.invoice.invoicepayment.submitted) return 'Submitted'
                if (this.invoice.invoicepayment.cancelled) return 'Cancelled'
                if (this.invoice.invoicepayment.confirmed) return 'Confirmed'
                if (this.invoice.invoicepayment.created) return 'Created'
                if (this.invoice.invoicepayment.paid_out) return 'Paid Out'
            }
            return 'No payment'
        },
        sent: {
            get() {
                return this.inSent
            },
            set(value) {
                this.$emit('sent', value)
                this.$emit('changed')
            },
        },
        approved: {
            get() {
                return this.inApproved
            },
            set(value) {
                this.$emit('approved', value)
                this.$emit('changed')
            },
        },
        paid: {
            get() {
                return this.inPaid
            },
            set(value) {
                this.$emit('paid', value)
                this.$emit('changed')
            },
        },
        toDate: {
            get() {
                return this.inToDate
            },
            set(value) {
                this.$emit('toDate', value.toISOString())
            },
        },
        fromDate: {
            get() {
                return this.inFromDate
            },
            set(value) {
                this.$emit('fromDate', value.toISOString())
            },
        },
        isLocked: {
            get() {
                return this.inLocked
            },
            set(value) {
                this.$emit('locked', value)
            },
        },
        disablePayment() {
            if (
                this.invoice &&
                (!this.invoice.broker ||
                    !this.invoice.broker.go_card_less_mandate_id ||
                    !this.invoice.broker.go_card_less_customer_id)
            )
                return true
            return false
        },
    },
    data() {
        return {
            invoice: null,
            show: {
                deleteModal: false,
                takePaymentModal: false,
                takePaymentApproveModal: false,
            },
        }
    },
    methods: {
        deleteInvoice() {
            this.$emit('deleteInvoice')
            this.show.deleteModal = false
        },
        closeApprovePayModal() {
            this.show.takePaymentApproveModal = false
            this.$emit('back')
        },
    },
}
</script>

<style scoped>
.showOverflow {
    overflow: visible;
}
</style>
