<template>
    <vx-card title="Invoice" collapse-action>
        <vue-ckeditor
            v-model="html"
            :config="config"
            @focus="$emit('changed')"
        />
    </vx-card>
</template>

<script>
import VueCkeditor from 'vue-ckeditor2'

export default {
    name: 'InvoiceCard',
    props: ['value'],
    components: {
        VueCkeditor,
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(data) {
                if (data) this.html = data
            },
        },
        html: {
            deep: true,
            immediate: true,
            handler(data) {
                this.$emit('input', data)
            },
        },
    },
    data() {
        return {
            html: null,
            config: {
                allowedContent: true,
                fillEmptyBlocks: false,
                removeFormatAttributes: '',
                fullPage: true,
                autoParagraph: false,
                height: '1200px',
                indent: false,
                breakBeforeOpen: false,
                breakAfterOpen: false,
                breakBeforeClose: false,
                breakAfterClose: false,
            },
        }
    },
    methods: {},
}
</script>

<style>
.cke_chrome {
    border: none;
}

.cke_reset::-webkit-scrollbar {
    width: 0 !important;
}
</style>
