<template>
    <vs-popup :title="title" :active.sync="showMain">
        <div class="vx-row vs-con-loading__container" id="loading-container">
            <div class="vx-col w-1/2 mb-3">
                <vs-row>
                    <div class="vx-col w-full mb-3">
                        <UserSelect
                            @selectedUser="setUser"
                            :initialUser="record.user_label"
                            :reset="!showMain"
                        ></UserSelect>
                    </div>
                    <div class="vx-col w-full mb-3">
                        <vs-input
                            v-model="record.name"
                            :danger="!record.name || record.name === ''"
                            type="text"
                            label="Client Name"
                            class="w-full"
                        ></vs-input>
                    </div>
                    <div class="vx-col w-full mb-3">
                        <label for="" class="vs-input--label">Date Sold</label>
                        <datepicker
                            placeholder="Date Sold"
                            v-model="record.date_sold"
                        ></datepicker>
                    </div>
                  <div class="vx-col w-full mb-3">
                    <label for="" class="vs-input--label">Date Added</label>
                    <datepicker
                        placeholder="Date Added"
                        v-model="record.date_added"
                    ></datepicker>
                  </div>

                    <div class="vx-col w-full mb-6">
                        <BrokerSelect
                            @selectedBroker="setBroker"
                            :initialBroker="record.broker_label"
                            :reset="!showMain"
                        ></BrokerSelect>
                    </div>

                    <div class="vx-col w-full">
                        <vs-textarea
                            v-model="record.notes"
                            label="Notes"
                        ></vs-textarea>
                    </div>
                </vs-row>
            </div>
            <div class="vx-col w-1/2 mb-3">
                <div>
                    <h5 class="mb-3">Products</h5>
                    <div v-if="commissions.length">
                        <div
                            class="flex items-center justify-between"
                            v-for="(item, index) in commissions"
                            :key="index"
                        >
                            <div class="">{{ commissionLabel(item) }}</div>
                            <div class="">
                                <vs-button
                                    radius
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-edit-2"
                                    @click="editCommission(item, index)"
                                ></vs-button>
                            </div>
                        </div>
                    </div>
                    <p v-else>No Commissions</p>
                </div>
                <vs-alert :active="!!commissions.length">
                    Total £{{ totalValue }}<br />
                    Commission £{{ totalCommission }}
                </vs-alert>
            </div>
        </div>

        <vs-divider />
        <div class="flex justify-between">
            <div class="flex w-full">
                <vs-button
                    @click="save"
                    :disabled="!validateForm"
                    class="mr-3"
                    color="success"
                    >Save</vs-button
                >
                <vs-button @click="cancel" type="flat">Cancel</vs-button>
                <vs-button
                    @click="newCommission"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-plus"
                    >Add Commission
                </vs-button>
            </div>
            <vs-button
                v-if="selectedCommissionRecord && selectedCommissionRecord.id"
                @click="deleteCommissionRecord"
                type="flat"
                color="secondary"
                icon-pack="feather"
                icon="icon-trash"
            ></vs-button>
        </div>

        <vs-popup :title="commissionTitle" :active.sync="show.commissionModal">
            <div class="vx-row">
                <div class="vx-col w-1/2 mb-3">
                    <div class="vx-row w-full">
                        <div class="vx-col w-full mb-3">
                            <label class="vs-input--label">Product</label>
                            <v-select
                                :options="productList"
                                v-model="selectedCommissionType"
                                placeholder=""
                            >
                            </v-select>
                        </div>
                        <div class="vx-col w-full mb-3">
                            <vs-input
                                type="text"
                                v-model="selectedCommission.title"
                                label="Title"
                                placeholder="Title"
                                class="w-full"
                            ></vs-input>
                        </div>
                        <div class="vx-col w-full mb-3">
                            <vs-textarea
                                v-model="selectedCommission.description"
                                label="Notes"
                                placeholder="Notes"
                            />
                        </div>
                    </div>
                </div>
                <div class="vx-col w-1/2 mb-3">
                    <div class="vx-row w-full">
                        <div class="vx-col w-full mb-3">
                            <vs-input
                                type="text"
                                v-model="selectedCommission.total"
                                label="Total"
                                v-on:input="calculatePrices()"
                                class="w-full"
                            ></vs-input>
                        </div>
                        <div class="vx-col w-full mb-3">
                            <vs-input
                                type="text"
                                v-model="selectedCommission.net"
                                label="Net"
                                class="w-full"
                            ></vs-input>
                        </div>
                        <div class="vx-col w-full mb-3">
                            <vs-input
                                type="text"
                                v-model="selectedCommission.vat"
                                label="Vat"
                                class="w-full"
                            ></vs-input>
                        </div>
                        <div class="vx-col w-full mb-3">
                            <vs-input
                                type="text"
                                v-model="selectedCommission.disbursements"
                                v-on:input="calculatePrices()"
                                label="Disbursements"
                                class="w-full"
                            ></vs-input>
                        </div>
                        <div class="vx-col w-full mb-3">
                            <vs-input
                                type="text"
                                v-model="selectedCommission.commission"
                                label="Commission"
                                class="w-full"
                            ></vs-input>
                        </div>
                    </div>
                </div>
            </div>
            <vs-divider> </vs-divider>
            <div class="flex w-full justify-between">
                <div>
                    <vs-button
                        @click="saveCommission"
                        :disabled="!validateCommissionForm"
                        class="mr-3"
                        color="success"
                    >
                        Save
                    </vs-button>
                    <vs-button @click="cancelCommission" type="flat"
                        >Cancel</vs-button
                    >
                </div>
                <vs-button
                    @click="deleteCommission"
                    v-show="selectedCommission.existingIndex !== null"
                    type="flat"
                    color="secondary"
                    icon-pack="feather"
                    icon="icon-trash"
                ></vs-button>
            </div>
        </vs-popup>
    </vs-popup>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'
import BrokerSelect from '@/components/common/BrokerSelect'
import UserSelect from '@/components/common/UserSelect'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

export default {
    name: 'CreateEditModal',
    props: ['showData', 'selectedCommissionRecord'],
    mixins: [textFormat],
    components: {
        BrokerSelect,
        UserSelect,
        Datepicker,
        vSelect,
    },
    watch: {
        selectedCommissionRecord: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value) {
                    this.record.id = value.id
                    this.record.name = value.name
                    this.record.user = value.user
                    this.record.date_sold = value.date_sold
                    this.record.date_added = value.date_added
                    this.record.broker = value.broker
                    this.record.notes = value.notes
                    this.record.user_label = value.user_widget_label
                    this.record.broker_label = value.broker_widget_label
                    this.commissions = value.commissions
                } else {
                    this.clearForm()
                }
            },
        },
    },
    data() {
        return {
            show: {
                commissionModal: false,
            },
            record: {
                id: null,
                name: null,
                date_sold: null,
                date_added: null,
                user: null,
                broker: null,
                notes: null,
                user_label: null,
                broker_label: null,
            },
            commissions: [],
            selectedCommission: {
                record: '',
                lasting_power_of_attorney: false,
                protective_property_trust: false,
                home_protection_trust: false,
                life_interest_trust: false,
                right_of_occupation: false,
                declaration_of_trust: false,
                disabled_persons_trust: false,
                will: false,
                title: '',
                description: '',
                total: 0,
                vat: 0,
                disbursements: 0,
                net: 0,
                commission: 0,
                existingIndex: null,
            },
            commissionTemplate: {
                record: '',
                lasting_power_of_attorney: false,
                protective_property_trust: false,
                home_protection_trust: false,
                life_interest_trust: false,
                right_of_occupation: false,
                declaration_of_trust: false,
                disabled_persons_trust: false,
                will: false,
                title: '',
                description: '',
                total: 0,
                vat: 0,
                disbursements: 0,
                net: 0,
                commission: 0,
                existingIndex: null,
            },
            productList: [
                { value: 1, label: 'Lasting Power of Attorney', price: 199 },
                { value: 2, label: 'Protective Property Trust', price: 349 },
                { value: 3, label: 'Home Protection Trust', price: 2299 },
                { value: 4, label: 'Life Interest Trust', price: 199 },
                { value: 5, label: 'Right of Occupation', price: 199 },
                { value: 6, label: 'Last Will & Testament', price: 139 },
                { value: 7, label: 'Declaration of Trust', price: 299 },
                { value: 8, label: 'Disabled Persons Trust', price: 349 },
            ],
        }
    },
    computed: {
        title() {
            if (this.record.id) return 'Edit Commission Record'
            return 'Create Commission Record'
        },
        commissionTitle() {
            if (this.selectedCommission.existingIndex !== null)
                return 'Edit Commission Product'
            return 'Create Commission Product'
        },
        showMain: {
            set(value) {
                this.$emit('close')
            },
            get() {
                return !!this.showData
            },
        },
        validateForm() {
            return !!this.record.name
        },
        validateCommissionForm() {
            return !!(
                this.selectedCommission.title && this.selectedCommission.total
            )
        },
        totalValue() {
            if (this.commissions.length) {
                var total = Object.values(this.commissions).reduce(
                    (acc, { total }) => Number(acc) + Number(total),
                    0
                )
                return total
            }
            return 0
        },
        totalCommission() {
            if (this.commissions.length) {
                var total = Object.values(this.commissions).reduce(
                    (acc, { commission }) => Number(acc) + Number(commission),
                    0
                )
                return total
            }

            return 0
        },
        selectedCommissionType: {
            set(value) {
                var selected = this.productList.find(
                    (item) => item.value === value.value
                )
                this.selectedCommission.title = selected.label
                this.selectedCommission.total = selected.price

                this.clearTypes()
                if (value.value == 1) {
                    this.selectedCommission.lasting_power_of_attorney = true
                } else if (value.value == 2) {
                    this.selectedCommission.protective_property_trust = true
                } else if (value.value == 3) {
                    this.selectedCommission.home_protection_trust = true
                } else if (value.value == 4) {
                    this.selectedCommission.life_interest_trust = true
                } else if (value.value == 5) {
                    this.selectedCommission.right_of_occupation = true
                } else if (value.value == 6) {
                    this.selectedCommission.will = true
                } else if (value.value == 7) {
                    this.selectedCommission.declaration_of_trust = true
                } else if (value.value == 8) {
                    this.selectedCommission.disabled_persons_trust = true
                }
                this.calculatePrices()
            },
            get() {
                if (this.selectedCommission.lasting_power_of_attorney)
                    return this.productList.find((item) => item.value === 1)
                else if (this.selectedCommission.protective_property_trust)
                    return this.productList.find((item) => item.value === 2)
                else if (this.selectedCommission.home_protection_trust)
                    return this.productList.find((item) => item.value === 3)
                else if (this.selectedCommission.life_interest_trust)
                    return this.productList.find((item) => item.value === 4)
                else if (this.selectedCommission.right_of_occupation)
                    return this.productList.find((item) => item.value === 5)
                else if (this.selectedCommission.will)
                    return this.productList.find((item) => item.value === 6)
                else if (this.selectedCommission.declaration_of_trust)
                    return this.productList.find((item) => item.value === 7)
                else if (this.selectedCommission.disabled_persons_trust)
                    return this.productList.find((item) => item.value === 8)
                return null
            },
        },
    },
    methods: {
        save() {
            this.startLoading()
            if (!this.record.id) this.saveNew()
            if (this.record.id) this.saveEdit()
        },
        saveNew() {
            var data = {
                record: this.record,
                commissions: null,
            }
            if (this.commissions) {
                var fd = []
                this.commissions.forEach((element) => {
                    fd.push(JSON.stringify(element))
                })
                data.commissions = JSON.stringify(fd)
            }
            http.post('commission_record_detail', data)
                .then((response) => {
                    this.stopLoading()
                    this.clearForm()
                    this.cancel()
                    this.$emit('update')
                    this.$vs.notify({
                        text: 'Commission Record Saved',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.stopLoading()
                })
        },
        saveEdit() {
            var data = {
                record: this.record,
                commissions: null,
            }
            if (this.commissions) {
                var fd = []
                this.commissions.forEach((element) => {
                    fd.push(JSON.stringify(element))
                })
                data.commissions = JSON.stringify(fd)
            }
            http.patch('commission_record_detail', data)
                .then((response) => {
                    this.stopLoading()
                    this.clearForm()
                    this.cancel()
                    this.$emit('update')
                    this.$vs.notify({
                        text: 'Commission Record Updates',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.stopLoading()
                })
        },
        cancel() {
            this.clearForm()
            this.showMain = false
        },
        clearForm() {
            this.record.id = null
            this.record.name = null
            this.record.date_sold = null
          this.record.date_added = null
            this.record.user = null
            this.record.broker = null
            this.record.notes = null
            this.record.user_label = null
            this.record.broker_label = null
            this.commissions = []
        },
        setBroker(value) {
            if (value) this.record.broker = value.value
            else this.record.broker = null
        },
        setUser(value) {
            if (value) {
                this.record.user = value.id
                this.record.name = value.name
            } else this.record.user = null
        },
        commissionLabel(value) {
            if (value.lasting_power_of_attorney)
                return 'Lasting Power of Attorney'
            else if (value.home_protection_trust) return 'Home Protection Trust'
            else if (value.life_interest_trust) return 'Life Interest Trust'
            else if (value.protective_property_trust)
                return 'Protective Property Trust'
            else if (value.right_of_occupation) return 'Right of Occupation'
            else if (value.will) return 'Complex Will'
            else if (value.declaration_of_trust) return 'Declaration of Trust'
            else if (value.disabled_persons_trust)
                return 'Disabled Persons Trust'
            return value.title
        },
        editCommission(commission, index) {
            Object.assign(this.selectedCommission, commission)
            this.selectedCommission.existingIndex = index
            this.show.commissionModal = true
        },
        clearTypes() {
            this.selectedCommission.lasting_power_of_attorney = false
            this.selectedCommission.protective_property_trust = false
            this.selectedCommission.home_protection_trust = false
            this.selectedCommission.life_interest_trust = false
            this.selectedCommission.right_of_occupation = false
            this.selectedCommission.declaration_of_trust = false
            this.selectedCommission.disabled_persons_trust = false
            this.selectedCommission.will = false
        },
        calculatePrices(commission) {
            this.selectedCommission.vat = this.calculateVat(
                this.selectedCommission.total
            )
            this.selectedCommission.net = this.calculateNet(
                this.selectedCommission.total,
                this.selectedCommission.disbursements,
                this.selectedCommission.vat
            )
            this.selectedCommission.commission = this.calculateCommission(
                this.selectedCommission.net
            )
        },
        calculateVat(total) {
            return (total - total / 1.2).toFixed(2)
        },
        calculateNet(total, disbursements, vat) {
            return (total - disbursements - vat).toFixed(2)
        },
        calculateCommission(net) {
            return (net * 0.25).toFixed(2)
        },
        saveCommission() {
            if (this.selectedCommission.existingIndex !== null) {
                this.commissions[
                    this.selectedCommission.existingIndex
                ] = Object.assign(new Object(), this.selectedCommission)
                this.cancelCommission()
            } else {
                this.commissions.push(
                    Object.assign(new Object(), this.selectedCommission)
                )
                this.cancelCommission()
                this.resetCommission()
            }
        },
        cancelCommission() {
            // this.resetCommission()
            this.show.commissionModal = false
        },
        newCommission() {
            this.resetCommission()
            this.show.commissionModal = true
        },
        resetCommission() {
            if (this.selectedCommission.id) delete this.selectedCommission.id
            this.selectedCommission.record = ''
            this.selectedCommission.lasting_power_of_attorney = false
            this.selectedCommission.protective_property_trust = false
            this.selectedCommission.home_protection_trust = false
            this.selectedCommission.life_interest_trust = false
            this.selectedCommission.right_of_occupation = false
            this.selectedCommission.declaration_of_trust = false
            this.selectedCommission.will = false
            this.selectedCommission.disabled_persons_trust = false
            this.selectedCommission.title = ''
            this.selectedCommission.description = ''
            this.selectedCommission.total = 0
            this.selectedCommission.vat = 0
            this.selectedCommission.disbursements = 0
            this.selectedCommission.net = 0
            this.selectedCommission.commission = 0
            this.selectedCommission.existingIndex = null
        },
        deleteCommission() {
            if (this.selectedCommission.id) {
                http.delete('commission_detail', {
                    params: { id: this.selectedCommission.id },
                })
                    .then((response) => {
                        this.commissions.splice(
                            this.selectedCommission.existingIndex,
                            1
                        )
                        this.$vs.notify({
                            text: 'Commission Removed',
                            color: 'primary',
                        })
                        this.cancelCommission()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else if (this.selectedCommission.existingIndex !== null) {
                this.commissions.splice(
                    this.selectedCommission.existingIndex,
                    1
                )
                this.$vs.notify({
                    text: 'Commission Removed',
                    color: 'primary',
                })
                this.cancelCommission()
            }
        },

        deleteCommissionRecord() {
            this.startLoading()
            http.delete('commission_record_detail', {
                params: { id: this.selectedCommissionRecord.id },
            })
                .then((response) => {
                    this.$vs.notify({
                        text: 'Commission Record Deleted',
                        color: 'primary',
                    })
                    this.showMain = false
                    this.$emit('update')
                    this.stopLoading()
                })
                .catch((error) => {
                    console.log(error)
                    this.stopLoading()
                })
        },
        startLoading() {
            this.$vs.loading({
                container: '#loading-container',
                scale: 0.6,
            })
        },
        stopLoading() {
            this.$vs.loading.close('#loading-container > .con-vs-loading')
        },
    },
}
</script>
