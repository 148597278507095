<template>
    <div v-if="invoiceData.data" id="page-user-view">
        <div class="vx-row">
            <div
                v-if="invoiceData.data"
                class="vx-col w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3"
            >
                <div class="vx-row">
                    <div class="vx-col w-full mb-6">
                        <ActionCard
                            @save="save"
                            @saveBack="save(true)"
                            @deleteInvoice="deleteInvoice"
                            :inLocked="invoiceData.data.locked"
                            :saveChanges="saveChanges"
                            :renderChanges="renderChanges"
                            @changed="saveChanges = true"
                            @locked="invoiceData.data.locked = $event"
                            :data="invoiceData.data"
                            :inSent="invoiceData.data.sent"
                            @sent="invoiceData.data.sent = $event"
                            :inApproved="invoiceData.data.approved"
                            @approved="invoiceData.data.approved = $event"
                            :inPaid="invoiceData.data.paid"
                            @paid="invoiceData.data.paid = $event"
                            :inToDate="invoiceData.data.to_date"
                            @toDate="invoiceData.data.to_date = $event"
                            :inFromDate="invoiceData.data.from_date"
                            @fromDate="invoiceData.data.from_date = $event"
                            @refreshPayment="refreshPayment"
                            @back="goBack"
                        ></ActionCard>
                    </div>
                    <div class="vx-col w-full mb-6">
                        <MetaCard
                            @changed="saveChanges = true"
                            :locked="invoiceData.data.locked"
                            :inDescription="invoiceData.data.description"
                            @description="invoiceData.data.description = $event"
                            :inAmount="invoiceData.data.amount"
                            @amount="invoiceData.data.amount = $event"
                            :inPaidDate="invoiceData.data.date_paid"
                            @paidDate="invoiceData.data.date_paid = $event"
                            :inNotes="invoiceData.data.notes"
                            @notes="invoiceData.data.notes = $event"
                        ></MetaCard>
                    </div>
                    <div class="vx-col w-full mb-6">
                        <WillsInvoiceCard
                            :data="invoiceData.wills"
                        ></WillsInvoiceCard>
                    </div>
                    <div class="vx-col w-full mb-6">
                        <CommissionsInvoiceCard
                            :data="invoiceData.commissions"
                            @update="invoiceDataFetch"
                        ></CommissionsInvoiceCard>
                    </div>
                </div>
            </div>

            <div class="vx-col w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <InvoiceCard
                            v-model="invoiceData.data.html"
                            @changed="saveChanges = true"
                        ></InvoiceCard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { http } from '@/services'

import ActionCard from './Cards/Action'
import InvoiceCard from './Cards/Invoice'
import MetaCard from './Cards/Meta'
import WillsInvoiceCard from './Cards/Wills'
import CommissionsInvoiceCard from '@/views/pages/Invoices/Invoice/Cards/Commissions'

export default {
    name: 'Invoice',
    props: [],
    components: {
        CommissionsInvoiceCard,
        ActionCard,
        InvoiceCard,
        MetaCard,
        WillsInvoiceCard,
    },
    created() {
        this.invoiceDataFetch(this.$route.params.pk)
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.invoiceDataFetch(this.$route.params.pk)
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    data() {
        return {
            invoiceData: {
                data: null,
                wills: null,
                commissions: null,
            },
            backPath: null,
            saveChanges: false,
            renderChanges: false,
        }
    },
    methods: {
        invoiceDataFetch(invoice_pk = null) {
            if (!invoice_pk && this.invoiceData.data.id)
                invoice_pk = this.invoiceData.data.id
            this.$vs.loading()
            http.get('invoice_detail', { params: { pk: invoice_pk } })
                .then((response) => {
                    this.invoiceData.data = response.data.invoice
                    this.invoiceData.wills = response.data.wills
                    this.invoiceData.commissions = response.data.commissions
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
        goBack() {
            if (this.backPath) this.$router.push(this.backPath)
            else this.$router.push('/invoices')
        },
        save(back = false) {
            http.patch('invoice_detail', this.invoiceData.data)
                .then((response) => {
                    if (back) {
                        this.goBack()
                    }
                    this.saveChanges = false
                    this.$vs.notify({
                        text: 'Invoice saved',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                })
        },
        deleteInvoice() {
            http.delete('invoice_detail', {
                params: { id: this.invoiceData.data.id },
            })
                .then((response) => {
                    this.$vs.notify({
                        text: 'Invoice deleted',
                        color: 'success',
                    })
                    if (this.backPath) this.$router.push(this.backPath)
                    else this.$router.push('/invoices')
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                })
        },
        refreshPayment() {
            http.get('payment_detail', {
                params: { invoice_id: this.invoiceData.data.id },
            })
                .then((response) => {
                    this.invoiceData.data.invoicepayment = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: 0.8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }

@media screen and (min-width: 1201px) and (max-width: 1211px),
    only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }

    // #account-manage-buttons {
    //   width: 12rem !important;
    //   flex-direction: column;

    //   > button {
    //     margin-right: 0 !important;
    //     margin-bottom: 1rem;
    //   }
    // }
}
</style>
