<template>
    <vx-card title="Meta" class="showOverflow">
        <div class="vx-row">
            <div class="vx-col w-full">
                <vs-textarea
                    :disabled="locked"
                    v-model="description"
                    label="Description"
                ></vs-textarea>
            </div>
            <div class="vx-col w-full">
                <vs-textarea
                    :disabled="locked"
                    v-model="notes"
                    label="Notes"
                ></vs-textarea>
            </div>
            <div class="vx-col w-full mb-3">
                <vs-input
                    :disabled="locked"
                    type="number"
                    class="w-full"
                    v-model="amount"
                    label="Amount"
                />
            </div>

            <div class="vx-col w-full mb-3">
                <label for="" class="vs-input--label">Paid Date</label>
                <datepicker
                    :disabled="locked"
                    type="number"
                    class="w-full"
                    v-model="paidDate"
                    label="Paid Date"
                />
            </div>
        </div>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import Datepicker from 'vuejs-datepicker'

export default {
    name: 'MetaCard',
    mixins: [textFormat],
    props: ['inDescription', 'inAmount', 'inPaidDate', 'inNotes', 'locked'],
    components: {
        Datepicker,
    },
    computed: {
        description: {
            get() {
                return this.inDescription
            },
            set(value) {
                this.$emit('description', value)
                this.$emit('changed')
            },
        },
        amount: {
            get() {
                return this.inAmount
            },
            set(value) {
                this.$emit('amount', value)
                this.$emit('changed')
            },
        },
        paidDate: {
            get() {
                return this.inPaidDate
            },
            set(value) {
                this.$emit('paidDate', value.toISOString())
                this.$emit('changed')
            },
        },
        notes: {
            get() {
                return this.inNotes
            },
            set(value) {
                this.$emit('notes', value)
                this.$emit('changed')
            },
        },
    },
    data() {
        return {
            invoice: null,
            show: {
                deleteModal: false,
            },
        }
    },
    methods: {},
}
</script>

<style scoped>
.showOverflow {
    overflow: visible;
}
</style>
