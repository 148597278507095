<template>
    <vx-card title="Commissions Included">
        <vs-table :data="data" stripe class="w-full">
            <template slot="thead">
                <vs-th>Client</vs-th>
                <vs-th>Date Sold</vs-th>
                <vs-th>Commission</vs-th>
                <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        <span>{{ tr.name }}</span>
                    </vs-td>

                    <vs-td>
                        <span>{{ formatDate(tr.date_sold) }}</span>
                    </vs-td>
                    <vs-td> £{{ tr.total_commission }} </vs-td>
                    <vs-td>
                        <vs-button
                            type="flat"
                            icon-pack="feather"
                            icon="icon-edit"
                            @click="commissionFetch(tr.id)"
                        ></vs-button
                    ></vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <CreateEditModal
            :showData="show.editModal"
            :selectedCommissionRecord="selectedCommissionRecord"
            @close="show.editModal = false"
            @update="$emit('update')"
        />
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import CreateEditModal from '@/views/pages/Commissions/All/CreateEdit'
import { http } from '@/services'

export default {
    name: 'CommissionsInvoiceCard',
    components: { CreateEditModal },
    mixins: [textFormat],
    props: ['data'],
    data() {
        return {
            selectedCommissionRecord: null,
            show: {
                editModal: false,
            },
        }
    },
    methods: {
        commissionFetch(pk) {
            http.get('commission_record_detail', { params: { pk: pk } })
                .then((response) => {
                    this.selectedCommissionRecord = response.data
                    this.show.editModal = true
                })
                .catch((error) => {
                    this.selectedCommissionRecord = null
                    console.log(error)
                })
        },
        setEdit(data) {
            this.selectedCommissionRecord = data
            this.show.editModal = true
        },
    },
}
</script>
