<template>
    <vs-popup title="Take GoCardless Payment" :active.sync="show">
        <p>
            Approve invoice and take £{{ invoiceData.amount }} as soon as
            possible from
            <span v-if="goCardLess.customer"
                >{{ goCardLess.customer.company_name }} ({{
                    goCardLess.customer.email
                }})</span
            >?
        </p>

        <div>
            <div v-if="goCardLess.mandate"></div>
            <p v-else>Unable to fetch go cardless mandate</p>
        </div>

        <vs-divider />

        <vs-button
            :disabled="!validateForm || loading"
            v-on:click="submitPayment"
            color="secondary"
            class="mr-3"
            >Confirm
        </vs-button>
        <vs-button v-on:click="$emit('close')" type="flat">Cancel </vs-button>
        <vs-progress v-show="loading" indeterminate color="primary"
            >primary</vs-progress
        >
    </vs-popup>
</template>

<script>
import { http } from '@/services'

export default {
    name: 'TakePaymentApproveModal',
    mixins: [],
    props: ['showData', 'invoiceData'],
    watch: {
        invoiceData: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.formData.invoice_id = value.id
                    if (value.broker.go_card_less_mandate_id)
                        this.getGoCardLessMandateData()
                    if (value.broker.go_card_less_customer_id)
                        this.getGoCardLessCustomerData()
                } else {
                    this.clearFrom()
                }
            },
        },
    },
    components: {},
    computed: {
        show: {
            get() {
                return this.showData
            },
            set(value) {
                this.$emit('close')
            },
        },
        validateForm() {
            return !!this.formData.invoice_id
        },
    },
    data() {
        return {
            formData: {
                invoice_id: null,
            },
            goCardLess: {
                mandate: null,
                customer: null,
            },
            loading: false,
        }
    },
    methods: {
        submitPayment() {
            if (this.formData.invoice_id) {
                this.loading = true
                http.post('broker_take_payment_approve', this.formData)
                    .then((response) => {
                        // this.$emit('close')
                        this.clearForm()

                        this.loading = false
                        this.$emit('close')

                        this.$vs.notify({
                            text: 'Payment scheduled',
                            color: 'success',
                        })
                        this.$nextTick(() => {
                            this.$emit('submitted')
                        })
                    })
                    .catch((error) => {
                        this.loading = false
                        this.$vs.notify({
                            text: error.response.data,
                            color: 'danger',
                        })
                    })
            }
        },
        clearForm() {
            this.formData.invoice_id = null
            this.goCardLess.mandate = null
            this.goCardLess.customer = null
        },
        getGoCardLessMandateData() {
            http.get('gocardless_mandate_detail', {
                params: {
                    mandate_id: this.invoiceData.broker.go_card_less_mandate_id,
                },
            })
                .then((response) => {
                    this.goCardLess.mandate = response.data
                    if (response.data.next_possible_charge_date) {
                        this.formData.date = new Date(
                            response.data.next_possible_charge_date
                        )
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getGoCardLessCustomerData() {
            http.get('gocardless_customer_detail_2', {
                params: {
                    customer_id: this.invoiceData.broker
                        .go_card_less_customer_id,
                },
            })
                .then((response) => {
                    this.goCardLess.customer = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
